import instance from "./axios"
import { useCookies } from 'vue3-cookies'
import router from '../router'
const { cookies } = useCookies();


const setup = (store) => {
  instance.interceptors.request.use(
    async (config) => {
      // console.log('axios.js request : ' , config);

      let auth_token = JSON.parse(localStorage.getItem('auth_token'));

      if(auth_token && auth_token.accessToken) {
        config.headers.Authorization = 'Bearer ' + auth_token.accessToken;
        // config.headers['x-access-token'] = user.accessToken;
        
        // config.headers['x-refresh-token'] = cookies.get('refreshToken');
        // config.headers['x-access-token']
        return config;
      } else {

        return config
      }
    }, 
    (error) => {
      console.error('axios.js request error : ', error);
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (res) => {
      // console.log('axios.js response : ' , res);
      if (res.method === 'get') {
        res.timeout = 30000;
      }
      return res;
      // return res
    },
    async (error) => {
      console.error('axios.js reqponse error : ', error);
    //   if(user && user.accessToken) {
        const errorRes = error.response;
        // const errorAPI = error.response.config;

        // if (cookies.get('refreshToken') === null) {
        //     // store.dispatch('auth/logout');
        //     return Promise.reject(error);
        // } else {
          // timeout이 발생한 경우와 서버에서 408 에러를 반환할 때를 동시에 처리하겠습니다.
          if (error.code === "ECONNABORTED" || error.response.status === 408) {
            // alert("요청이 만료되었습니다.");

            return Promise.reject(error);
          }

          if (error.response.status === 419) { // accessToken이 null일 경우 419코드를 받고 토큰 재생성 요청
            try {
            //   await store.dispatch('auth/refreshToken');
              return instance(errorAPI);
            } catch (err) {
              // console.error('err);
              return Promise.reject(err);
            }
          }
          if (error.response.status === 401) { //accessToken이 변조 등 유효하지 않은 토큰일 경우
            console.warn('유효하지   않은 토큰', error);
            console.log("401 에러 !!!!")
            store.dispatch('auth/logout');
            // alert('다시 로그인해주시기 바랍니다.');

            router.push('/login');

            return Promise.reject(error);
          }
          if (error.response.status === 403) { //accessToken이 변조 등 유효하지 않은 토큰일 경우
            console.warn('유효하지   않은 토큰', error);
            console.log("401 에러 !!!!")
            store.dispatch('auth/logout');
            // alert('다시 로그인해주시기 바랍니다.');

            router.push('/login');

            return Promise.reject(error);
          }
        // }
    //   }
    //   return Promise.reject(error);
    }
  )
}
export default setup;